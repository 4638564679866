import request from '../request'

/** 接口管理 */
const Api = {
    page: '/api/sys/authCenter/list',
    save: '/api/sys/authCenter/save',
    edit: '/api/sys/authCenter/edit',
    remove: '/api/sys/authCenter/remove',
    removeBatch: '/api/sys/authCenter/removeBatch',
    counselorList: '/api/sys/authCenter/counselorList',
    isRecommend: '/api/sys/authCenter/isRecommend',
    isState: '/api/sys/authCenter/isState',
}

/** 列表 (分页) */
export const page = data => {
    return request.request({
        url: Api.page,
        params: data,
        method: 'GET'
    })
}
/** 人员列表 (分页) */
export const counselorList = data => {
    return request.request({
        url: Api.counselorList,
        params: data,
        method: 'GET'
    })
}
/** 设置推荐 */
export const setRecommend = data => {
    return request.request({
        url: Api.isRecommend,
        data: data,
        method: 'PUT'
    })
}
/** 启用禁用 */
export const setState = data => {
    return request.request({
        url: Api.isState,
        data: data,
        method: 'PUT'
    })
}

/** 保存 */
export const save = data => {
    return request.request({
        url: Api.save,
        data: data,
        method: 'POST'
    })
}

/** 修改 */
export const edit = data => {
    return request.request({
        url: Api.edit,
        data: data,
        method: 'PUT'
    })
}

/** 删除 */
export const remove = data => {
    return request.request({
        url: Api.remove,
        params: data,
        method: 'DELETE'
    })
}

/** 批量删除 */
export const removeBatch = data => {
    return request.request({
        url: Api.removeBatch,
        params: data,
        method: 'DELETE'
    })
}