<template>
  <a-config-provider :locale="antdLocal">
    <router-view></router-view>
  </a-config-provider>
</template>
<script>
  import {useStore} from "vuex";
  import {computed, defineComponent, ref} from "vue";
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import enUS from 'ant-design-vue/es/locale/en_US';
  import dayjs from "dayjs";
  import "dayjs/locale/zh-cn";

  export default defineComponent({
    name: 'App',
    setup() {

      const store = useStore()
      
      const language = computed(() => store.getters['language'])
      const color = computed(() => store.getters.color);

      const antdLocal = ref(
      computed(() => {
        switch (language.value) {
          case 'zh-cn':
            dayjs.locale(zhCN.locale);
            return zhCN;
          case 'en-us':
            dayjs.locale(enUS.locale);
            return enUS;
          default:
            dayjs.locale(zhCN.locale);
            return zhCN;
        }
      })
    )

    setTimeout(function(){
      window.less.modifyVars({
        "primary-color": color.value,
      })
    },10)

    return {
      antdLocal,
      dayjs
    }
  }
})
</script>
