import { page } from "@/api/module/dictData";
import { tree as getDeptList } from "@/api/module/dept";
import { page as getPostList } from "@/api/module/post";
import { page as getAuthCenterList } from "@/api/module/authAccount";

const state = {
  counselorLevel:  [],
  consultType:  [],
  contentTop:  [],
  contentSecond:  [],
  depts: [],
  posts: [],
  authCenter: [],
}

const mutations = {
  SET_COUNSELOR_LEVEL(state, list) {
    state.counselorLevel = list;
  },
  SET_CONSULT_TYPE(state, list) {
    state.consultType = list;
  },
  SET_CONTENT_TOP(state, list) {
    state.contentTop = list;
  },
  SET_CONTENT_SECOND(state, list) {
    state.contentSecond = list;
  },
  SET_DEPTS(state, list) {
    state.depts = list;
  },
  SET_POSTS(state, list) {
    state.posts = list;
  },
  SET_AUTH_CENTER(state, list) {
    state.authCenter = list;
  },
}

const actions = {

  async getCounselorLevel( {commit} ) {
    const res = await page({code: 'counselor_level', pageNum: 1, pageSize: 1000});
    if (res.code === 200) {
      let list = res.data.record.map(item => {
        return {
          text: item.label,
          value: item.value
        }
      })
      commit('SET_COUNSELOR_LEVEL', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },
  async getConsultType( {commit} ) {
    const res = await page({code: 'consultType', pageNum: 1, pageSize: 1000});
    if (res.code === 200) {
      let list = res.data.record.map(item => {
        return {
          text: item.label,
          value: item.value
        }
      })
      commit('SET_CONSULT_TYPE', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },
  async getContentTop( {commit} ) {
    const res = await page({code: 'content_top', pageNum: 1, pageSize: 1000});
    if (res.code === 200) {
      let list = res.data.record.map(item => {
        return {
          text: item.label,
          value: item.value
        }
      })
      commit('SET_CONTENT_TOP', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },
  async getContentSecond( {commit} ) {
    const res = await page({code: 'content_second', pageNum: 1, pageSize: 1000});
    if (res.code === 200) {
      let list = res.data.record.map(item => {
        return {
          text: item.label,
          value: item.value
        }
      })
      commit('SET_CONTENT_SECOND', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },
  async getDepts( {commit} ) {
    const res = await getDeptList();
    if (res.code === 200) {
      let list = res.data;
      commit('SET_DEPTS', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },
  async getPosts( {commit} ) {
    const res = await getPostList({pageNum: 1, pageSize: 1000});
    if (res.code === 200) {
      let list = res.data.record;
      commit('SET_POSTS', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },
  async getAuthCenter( {commit} ) {
    const res = await getAuthCenterList({pageNum: 1, pageSize: 1000});
    if (res.code === 200) {
      let list = res.data.record;
      commit('SET_AUTH_CENTER', list);
      return Promise.resolve();
    } else {
      return Promise.reject(res.msg);
    }
  },

}
export default {
  namespaced: true,
  mutations,
  actions,
  state,
}
