import Layout from "@/layout";

export default {
   Layout,
  'dashboard-workspace': () => import('@/view/space/index.vue'),
  'result-success': () => import('@/view/result/success.vue'),
  'result-failure': () => import('@/view/result/failure.vue'),
  'error-403': () => import('@/view/error/403.vue'),
  'error-404': () => import('@/view/error/404.vue'),
  'error-500': () => import('@/view/error/500.vue'),
  'user': () => import('@/view/user/index.vue'),
  'role': () => import('@/view/role/index.vue'),
  'log-oper': () => import('@/view/log/oper.vue'),
  'log-auth': () => import('@/view/log/auth.vue'),
  'power': () => import('@/view/power/index.vue'),
  'dict': () => import('@/view/dict/index.vue'),
  'post': () => import('@/view/post/index.vue'),
  'dept-list': () => import('@/view/dept/index.vue'),
  'server': () => import('@/view/server/index.vue'),
  'online': () => import('@/view/online/index.vue'),
  'doc': () => import('@/view/doc/index.vue'),
  'oss': () => import('@/view/oss/index.vue'),
  'dept': () => import('@/view/dept/index.vue'),
  'baseInfo': () => import('@/view/baseInfo/index.vue'),
  'show': () => import('@/view/show/index.vue'),
  'recruit': () => import('@/view/recruit/index.vue'),
  'leaveWord': () => import('@/view/leaveWord/index.vue'),
  'noticeMsg': () => import('@/view/noticeMsg/index.vue'),
  'content': () => import('@/view/content/index.vue'),
  'news': () => import('@/view/news/index.vue'),
  'config': () => import('@/view/config/index.vue'),
  'xinDev': () => import('@/view/xinDev/index.vue'),
  'subject': () => import('@/view/subject/index.vue'),
  'apply': () => import('@/view/apply/index.vue'),
  'mentor': () => import('@/view/mentor/index.vue'),
  'problem': () => import('@/view/problem/index.vue'),
  'banner': () => import('@/view/banner/index.vue'),
  'auth': () => import('@/view/auth/index.vue'),
  'authCounselor': () => import('@/view/authCounselor/index.vue'),
  'authNews': () => import('@/view/authNews/index.vue'),
  'authCenter': () => import('@/view/authCenter/index.vue'),
  'authAccount': () => import('@/view/authAccount/index.vue'),
  'contentReview': () => import('@/view/contentReview/index.vue'),
}
