<template>
  <div class="page-layout">
      <slot></slot>
  </div>
</template>
<script>
import "./index.less";
import { useStore } from "vuex";
export default {
  name:"page-layout",
  setup() {
    

  }
}
</script>