<template>
  <div class="page-footer">
    <div style="text-align: center">
      <span class="page-footer-title">
          <a href="http://www.pearadmin.com">官网</a>
      </span>
      <span class="page-footer-title">
          <a href="http://www.pearadmin.com/">社区</a>
      </span>
      <span class="page-footer-title">
          <a href="http://forum.pearadmin.com/">论坛</a>
      </span>
    </div>
    <div class="page-footer-copyright" style="text-align: center">Copyright © 2020 &nbsp;&nbsp; 爸妈在线</div>
  </div>
</template>
<script>
import "./index.less";
export default {
  name: "page-footer"
};
</script>
